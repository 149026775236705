<template>
  <a @mouseover="closeMenu" href="https://coredacao.com/whatsapp-suporte" target="_blank">
    <VBtn rounded="full" color="white" :icon="true" size="42">
      <img src="/images/icons/help.svg" class="w-7 h-7 opacity-50" />
    </VBtn>
  </a>
</template>

<script lang="ts" setup>
import { useNavPopover } from '../composables/useNavPopover';

const { closeMenu } = useNavPopover();
</script>