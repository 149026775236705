import { MenuCorrections, MenuEssays, MenuMore, MenuNotifications, MenuProfile } from '../components/menus'

type NavMenu = 'profile' | 'essays' | 'corrections' | 'more' | 'notifications'

const isOpen = ref(false)
const section = ref<NavMenu | null>(null)

export function useNavPopover() {
  const component = computed(() => {
    switch (section.value) {
      case 'profile':
        return MenuProfile
      case 'essays':
        return MenuEssays
      case 'corrections':
        return MenuCorrections
      case 'more':
        return MenuMore
      case 'notifications':
        return MenuNotifications
      default:
        return ''
    }
  })

  const openMenu = (menu: NavMenu) => {
    section.value = menu
    isOpen.value = true
  }

  const closeMenu = () => {
    isOpen.value = false
  }

  // Handle route change
  const route = useRoute()

  watch(
    () => route.path,
    () => {
      isOpen.value = false
    },
  )

  return {
    isOpen,
    section,
    component,
    closeMenu,
    openMenu,
  }
}
