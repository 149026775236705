import { useMutation } from '@tanstack/vue-query'
import type { OnboardingData } from '../composables/useOnboarding'
import { useApi } from '~/domains/global/composables/useApi'

export const useSaveOnboardingDataMutation = () => {
  const api = useApi()
  return useMutation({
    mutationFn: async (data: OnboardingData) => {
      await api('/users/onboarding', {
        method: 'POST',
        body: data,
      })
    },
  })
}
