<template>
  <ul class="-mx-6 -my-6">
    <li
      v-for="item in items"
      :key="item.name"
      v-ripple="{ class: `primary` }"
      class="select-none px-6 py-3 border-t cursor-pointer hover:bg-primary/5 font-bold text-primary flex items-center gap-2"
      @click="item.action"
    >
      <img :src="item.icon" class="w-5 h-5" /> {{ item.name }}
    </li>

    <a href="https://respostacerta.com" target="_blank">
      <li
        v-ripple="{ class: `primary` }"
        class="select-none px-6 py-3 border-t cursor-pointer hover:bg-primary/5 font-bold text-primary flex items-center gap-2"
      >
        <img src="/images/dashboard/icons/resposta-certa_on.svg" class="w-5 h-5" /> Questões ENEM ↗️
      </li>
    </a>
  </ul>
</template>

<script lang="ts" setup>
import { useNavPopover } from "../../composables/useNavPopover";

const router = useRouter();

const { closeMenu } = useNavPopover();

const items = [
  {
    name: "Apostila Redação ENEM",
    icon: "/images/dashboard/icons/theme_on.svg",
    action: () => {
      router.push("/aprenda/apostila");
      closeMenu();
    },
  },
  {
    icon: "/images/icons/essay_models.png",
    name: "Modelos de Redação",
    action: () => {
      router.push("/conteudo/artigos/modelos-de-redacao/");
      closeMenu();
    },
  },
  {
    name: "Repertórios Socioculturais",
    icon: "/images/icons/book.png",
    action: () => {
      router.push("/conteudo/artigos/repertorio-sociocultural/");
      closeMenu();
    },
  },
];
</script>
