<template>
  <div>
    <OnboardingHeader title="Como você descobriu o coRedação?">
      Última pergunta. Obrigado!
    </OnboardingHeader>

    <Chooser
      v-model="data.source"
      class="mt-4"
      :options="[
        {
          value: 'GOOGLE',
          name: 'Google',
        },
        {
          value: 'REDE_SOCIAL',
          name: 'Rede social',
        },
        {
          value: 'INDICACAO_PROFESSOR',
          name: 'Indicação de professor',
        },
        {
          value: 'INDICACAO_AMIGO',
          name: 'Indicação de amigo',
        },
        {
          value: 'OUTRO',
          name: 'Outro',
        },
      ]"
    />
  </div>
</template>

<script lang="ts" setup>
import OnboardingHeader from '../header.vue'
import Chooser from '../chooser.vue'
import { useOnboarding } from '../../composables/useOnboarding'

const { data } = useOnboarding()
</script>
