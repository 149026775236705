<template>
  <nav class="hidden md:block h-full">
    <ul class="flex h-full md:-ml-6">
      <li
        v-for="(m, i) in navLinks"
        :key="i"
        v-ripple="{ class: `text-primary/50` }"
        class="flex gap-4 items-center px-6 cursor-pointer rounded text-primary transition-all"
        :class="{
          'hover:!opacity-70 opacity-50 !text-black': route.fullPath !== m.to,
        }"
        @click="router.push(m.to)"
        @mouseover="closeMenu()"
      >
        <div class="relative">
          <img class="w-7 h-7" :src="`/images/dashboard/icons/${m.icon}.svg`" />
          <img
            :class="{ 'opacity-100 ': route.fullPath == m.to }"
            class="opacity-0 absolute top-0 w-8 h-8 transition-all"
            :src="`/images/dashboard/icons/${m.icon}_on.svg`"
          />
        </div>
        <span class="uppercase font-bold text-sm">
          {{ m.title }}
        </span>
      </li>

      <!-- More -->
      <li
        class="flex gap-4 items-center px-6 cursor-default rounded transition-all"
        :class="{ 'hover:!opacity-70 opacity-50 text-black': true }"
        @mousemove="openMenu('more')"
      >
        <div class="relative">
          <img class="w-7 h-7" :src="`/images/dashboard/icons/more.svg`" />
        </div>
        <span class="uppercase font-bold text-sm"> Mais </span>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { useNavPopover } from "../composables/useNavPopover";

const navLinks = [
  {
    title: "Redações",
    to: "/",
    icon: "essays",
    icon_on: "essays_on",
  },
  {
    title: "Curso",
    to: "/aprenda/curso-enem",
    icon: "panel",
    icon_on: "panel_on",
  },
];

const { openMenu, closeMenu } = useNavPopover();
const router = useRouter();
const route = useRoute();
</script>
