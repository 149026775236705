<template>
  <div class="flex items-center gap-4">
    <VTooltip
      text="Precisa de ajuda?"
      location="bottom"
      v-if="false"
    >
      <template #activator="{ props }">
        <VBtn
          v-bind="props"

          rounded="circle"
          variant="outlined"
          :size="36"
          color="primaryAlt"
        >
          <VIcon icon="mdi-account-question-outline" />
        </VBtn>
      </template>
    </VTooltip>

    <img
      src="/images/icons/user.svg"
      class="w-[36px] h-[36px] rounded-full opacity-40 hover:!opacity-80"
      alt="Usuário"
      @mouseover="openMenu('profile')"
    >
  </div>
</template>

<script lang="ts" setup>
import { useNavPopover } from '../composables/useNavPopover'

const { openMenu } = useNavPopover()
</script>
