<template>
  <VDialog :close-on-back="false" @after-leave="close" :model-value="isOpen" max-width="500">
    <VCard class="md:px-2 pb-4">
      <div class="p-4">
        <h2 class="text-2xl">Modo de escrita</h2>
        <p>Como deseja escrever sua redação?</p>

        <hr class="border-accent/20 my-4 border-b-[1px]" />

        <div class="grid gap-4">
          <div
            @click="mode.action"
            v-ripple="{ class: `text-primary/50` }"
            v-for="mode in modes"
            :key="mode.title"
            class="relative cursor-pointer select-none hover:bg-primary/5 justify-start text-left rounded-[20px] border-accent/40 border-2 p-4 flex gap-4 items-center"
          >
            <div>
              <img :src="mode.icon" class="size-12" />
            </div>
            <div>
              <h3>
                {{ mode.title }}
              </h3>
              <p class="text-sm">
                {{ mode.description }}
              </p>
            </div>
            <div class="absolute top-0 -translate-y-1/2 left-4 text-xs bg-primary rounded-xl px-2 font-semibold" v-if="mode.new">
              Novidade
            </div>
          </div>
        </div>
      </div>

      <!-- Close button -->
      <VBtn
        icon="mdi-close"
        size="small"
        class="shadow-xl fixed md:absolute top-4 right-4 border-[1px] !border-black/50"
        @click="close"
      />
    </VCard>
  </VDialog>
</template>

<script lang="ts" setup>
import { useWriteSelector } from "../composables/useWriteSelector";

const { isOpen, close } = useWriteSelector();
const router = useRouter();

const modes = [
  {
    title: "Redação treino",
    icon: "/images/icons/write-free.svg",
    description: "Escolha um tema e escreva pela plataforma.",
    action: () => {
      router.push("/escrever");
    },
  },
  {
    title: "Redação guiada",
    icon: "/images/icons/write-guided.svg",
    description: "Escreva uma redação com um guia frase a frase.",
    new: true,
    action: () => {
      router.push("/escrita-guiada");
    },
  },
  // {
  //   title: "Redação por imagem",
  //   icon: "/images/icons/gallery.svg",
  //   description: "Escreva no papel e envie por imagem.",
  //   new: true,
  //   action: () => {
  //     router.push("/escrever?tipo=imagem");
  //   },
  // },

];
</script>
