<template>
  <VBtn
    size="42"
    @mouseover="handleMenu"
    class="text-none group"
    icon
    rounded="full"
    color="white"
    :loading="isPending"
  >
    <VBadge
      :model-value="!!unreadNotifications"
      color="primary"
      :content="unreadNotifications"
    >
      <img src="/images/icons/bell.svg" class="w-7 h-7 opacity-50" />
    </VBadge>
    <!-- Notification viwer modal -->
    <NotificationViewer />
  </VBtn>
  
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { useNavPopover } from "../composables/useNavPopover";
import { getNotificationsQuery } from "../queries/getNotificationsQuery";
import NotificationViewer from "./notificationViewer.vue";

const { openMenu } = useNavPopover();
const { isPending, data } = useQuery(getNotificationsQuery());

const handleMenu = () => {
  if (!isPending.value) {
    openMenu("notifications");
  }
};

const unreadNotifications = computed(
  () => data.value?.filter((notification) => !notification.read).length
);
</script>
