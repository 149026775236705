<template>
  <VFadeTransition>
    <div
      v-if="isLoading"
      class="gap-6 flex flex-col items-center fixed top-0 left-0 w-screen h-screen justify-center"
    >
      <div class="flex items-center gap-4">
        <img
          src="/images/intro/logo.svg"
          alt="Logo"
          class="w-10 animate-bounce"
        />
        <img class="w-[200px]" src="/images/intro/text.svg" />
      </div>

      <div class="w-[300px] max-w-full">
        <VProgressLinear color="primary" height="10" rounded indeterminate />
      </div>
    </div>
  </VFadeTransition>
</template>

<script lang="ts" setup>
import { useAuth } from '~/domains/auth/composables/useAuth';

const { isLoading } = useAuth()
</script>
