<template>
  <div class="">
    <!-- Popover -->
    <VFadeTransition>
      <div
        v-if="isOpen"
        class="absolute top-16"
        :class="{
          'md:left-[300px]': section === 'more',
          'right-4 md:right-0': section !== 'more',
        }"
      >
        <VCard>
          <VSlideXTransition
            hide-on-leave
            group
            mode="out-in"
          >
            <Component
              :is="component"
              :key="section"
              class="md:w-[380px] w-[calc(100vw-32px)] p-6"
            />
          </VSlideXTransition>
        </VCard>
      </div>
    </VFadeTransition>

    <!-- Overlay -->
    <Teleport to="#app">
      <VFadeTransition>
        <div
          v-if="isOpen"
          class="fixed top-0 h-screen w-screen bg-white/80 left-0"
          @mousemove="isOpen = false"
        />
      </VFadeTransition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { useNavPopover } from '../composables/useNavPopover'

defineSlots<{
  default(): any
}>()

const { isOpen, component, section } = useNavPopover()
</script>
