<template>
  <button
    v-ripple="{ class: `text-primary/50` }"
    :class="{
      '!pb-4': isMobileInstalled,
    }"
    @click="isOpen = false"
    class="flex select-none flex-col justify-center items-center py-1 px-4 rounded-xl gap-1 w-full"
  >
    <img class="size-5" v-show="!active" :src="inactiveImg" />
    <img class="size-5" v-show="active" :src="activeImg" />
    <span
      class="text-sm font-semibold"
      :class="{
        'text-primary': active,
        'text-black/50': !active,
      }"
      >{{ label }}</span
    >
  </button>
</template>

<script lang="ts" setup>
import { useNavPopover } from "../composables/useNavPopover";

const props = defineProps<{
  active: boolean;
  activeImg: string;
  inactiveImg: string;
  label: string;
}>();

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

const isMobileInstalled = computed(() => {
  return $pwa?.isPWAInstalled && isIOS();
})

const { $pwa } = useNuxtApp();
const { isOpen } = useNavPopover();
</script>
