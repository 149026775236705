<template>
  <div class="flex items-center gap-8">
    <div
      class="flex gap-2 items-center cursor-default"
      @mouseover="openMenu('essays')"
    >
      <img
        class="w-6 h-6"
        src="/images/icons/flash.png"
      >

      <span class="font-bold">
        {{ data?.essaysTotal ?? 0 }}
      </span>
    </div>

    <div
      class="flex gap-2 items-center cursor-default"
      @mouseover="openMenu('corrections')"
    >
      <img
        class="w-6 h-6"
        src="/images/icons/pencil_credit.png"
      >

      <span class="font-bold">
        {{ data?.correctionsInMonth ?? 0 }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNavPopover } from '../composables/useNavPopover'
import { useAuth } from '~/domains/auth/composables/useAuth'

const { openMenu } = useNavPopover()

const { data } = useAuth()
</script>
