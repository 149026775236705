<template>
  <div>
    <div class="flex gap-6 w-full">
      <img class="w-12 h-12" src="/images/icons/pencil_credit.png" />
      <div class="w-full">
        <h3>Correções</h3>

        <div class="mt-4">
          <div class="flex text-sm">
            <span>Uso no mês</span>

            <span
              v-if="!data?.isPremium"
              class="inline-block ml-auto font-bold"
            >
              {{ correctionsInMonth }} / Ilimitado
            </span>
            <span v-else class="inline-block ml-auto font-bold">
              Ilimitado
            </span>
          </div>

          <VProgressLinear :value="100" rounded color="success" height="10" />
        </div>

        <div class="text-sm mt-4">
          Agora você corrige quantas redações quiser, sem limites.
        </div>
      </div>
    </div>

    <VDivider class="my-4" />

    <div v-if="data?.isPremium">
      <VBtn @click="openWriteSelector" block size="default"> Enviar redação </VBtn>
    </div>
    <div v-else class="flex flex-col items-center">
      <PaywallBanner />
      <PaywallBenefits class="mt-4" />

      <VBtn @click="openCheckout" block size="default" class="mt-4">
        <img src="/images/icons/star.png" class="w-4 h-4 mr-2" />
        <span>Destravar minha redação</span>
      </VBtn>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuth } from "~/domains/auth/composables/useAuth";
import { useCheckout } from "~/domains/checkout/composables/useCheckout";
import { PaywallBanner, PaywallBenefits } from "~/domains/paywall/components";
import { useWriteSelector } from "~/domains/write/composables/useWriteSelector";

const { data } = useAuth();

const correctionsInMonth = computed(() => data.value?.correctionsInMonth ?? 0);
const { openCheckout } = useCheckout();
const { open: openWriteSelector } = useWriteSelector();
</script>
