import { queryOptions } from "@tanstack/vue-query";
import { useApi } from "~/domains/global/composables/useApi";

export const getNotificationsQuery = () => {
  return queryOptions({
    queryKey: ["notifications"],
    queryFn: async () => {
      const api = useApi();
      const data = await api<UserNotification[]>("/notification/my", {
        method: "GET",
      });

      return data;
    },
    gcTime: 1000 * 60 * 35,
    staleTime: 1000 * 60 * 30,
  });
};

export type UserNotification = {
  id: string;
  title: string;
  read: boolean;
  createdAt: string;
};
