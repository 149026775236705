<template>
  <div>
    <OnboardingHeader title="Você está se preparando para qual prova?">
      Isso vai nos ajudar a personalizar sua experiência.
    </OnboardingHeader>

    <Chooser
      v-model="data.exam"
      class="mt-4"
      :options="options"
    />
  </div>
</template>

<script lang="ts" setup>
import OnboardingHeader from '../header.vue'
import Chooser from '../chooser.vue'
import { useOnboarding } from '../../composables/useOnboarding'

const { data } = useOnboarding()

const options = [
  {
    value: 'ENEM',
    name: 'ENEM',
  },
  {
    value: 'CONCURSO',
    name: 'Concurso',
  },
  {
    value: 'FUVEST',
    name: 'Fuvest',
  },
  {
    value: 'OUTRO_VESTIBULAR',
    name: 'Outro vestibular',
  },
]
</script>
