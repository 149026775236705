<template>
  <VItemGroup :value="model">
    <div>
      <div class="flex flex-col gap-3">
        <div
          v-for="(opt, i) in options"
          :key="i"
        >
          <VItem v-slot="{}">
            <VCard
              class="flex items-center rounded-3xl border-[1px] border-accent/50 shadow-md transition-all"
              flat
              :class="{
                new: opt.value === model,
              }"
              :style="{
                color: opt.value === model ? 'white' : 'black',
              }"
              :color="opt.value === model ? 'primary' : 'white'"
              height="auto"
              @click="model = opt.value"
            >
              <div class="px-2 py-2 mx-auto !font-semibold text-center">
                {{ opt.name }}
              </div>
            </VCard>
          </VItem>
        </div>
      </div>
    </div>
  </VItemGroup>
</template>

<script lang="ts" setup>
defineProps<{
  options: Array<{
    value: string | number
    name: string
  }>
}>()

const model = defineModel<string | number>()
</script>
