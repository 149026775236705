import StepOne from './stepOne.vue'
import StepTwo from './stepTwo.vue'
import StepThree from './stepThree.vue'
import StepFour from './stepFour.vue'
import StepFive from './stepFive.vue'

export const OnboardingSteps = [
  {
    component: StepOne,
    key: 'stepOne',
  },
  {
    component: StepTwo,
    key: 'stepTwo',
  },
  {
    component: StepThree,
    key: 'stepThree',
  },
  {
    component: StepFour,
    key: 'stepFour',
  },
  {
    component: StepFive,
    key: 'stepFive',
  },
]
