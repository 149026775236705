<template>
  <VDialog
    :model-value="shouldShow"
    :fullscreen="display.smAndDown.value"
    :persistent="true"
    :max-width="900"
  >
    <div
      ref="modalContainer"
      class="bg-white h-screen overflow-auto md:!overflow-x-hidden md:!h-full md:rounded-3xl p-4 md:p-10 flex flex-col items-center"
    >
      <ConfettiExplosion :stage-height="550" class="mx-auto" />

      <div
        class="absolute z-40 bg-white px-4 md:!px-10 flex w-full top-0 h-[60px] md:rounded-3xl items-center"
      >
        <VProgressLinear
          height="10"
          :model-value="progress"
          color="primary"
          rounded="full"
          class=""
        />

        <VBtn
          v-if="step > 0"
          class="absolute left-4 top-1/2 -translate-y-1/2"
          rounded="full"
          @click="goBackStep"
        >
          <VIcon icon="mdi-chevron-left" size="30" />
        </VBtn>
      </div>

      <div class="mt-14 md:!mt-10">
        <Component
          :is="currentStepComponent.component"
          :key="currentStepComponent.key"
        />
      </div>

      <VBtn
        flat
        rounded="full"
        color="primary"
        size="large"
        class="mt-8"
        @click="goToNextStep"
      >
        <span class="px-4">Continuar</span>
      </VBtn>
    </div>
  </VDialog>
</template>

<script lang="ts" setup>
import ConfettiExplosion from "vue-confetti-explosion";
import { useOnboarding } from "../composables/useOnboarding";
import { useDisplay } from "vuetify";

const {
  shouldShow,
  progress,
  currentStepComponent,
  step,
  goBackStep,
  goToNextStep,
} = useOnboarding();

const display = useDisplay();

const modalContainer = ref<HTMLElement | null>(null);

watch(currentStepComponent, () => {
  // Scroll to top
  modalContainer.value?.scrollTo({ top: 0, behavior: "instant" });
});
</script>
