<template>
  <div>
    <div class="flex gap-6">
      <img class="w-14 h-14" src="/images/icons/flash.png" />
      <div>
        <h3>Redações escritas</h3>

        <span>
          Você escreveu <b>{{ numberOfEssays }}</b> redações.
          <span v-if="numberOfEssays">Continue assim!</span>
        </span>
      </div>
    </div>

    <VDivider class="my-4" />

    <VBtn block size="default" @click="openWriteSelector">
      Enviar redação
    </VBtn>
  </div>
</template>

<script lang="ts" setup>
import { useAuth } from "~/domains/auth/composables/useAuth";
import { useWriteSelector } from "~/domains/write/composables/useWriteSelector";

const { data } = useAuth();

const numberOfEssays = computed(() => data.value?.essaysTotal ?? 0);
const { open: openWriteSelector } = useWriteSelector();
</script>
