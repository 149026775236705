<template>
  <div>
    <OnboardingHeader :title="title">
      Isso nos ajudará a entender melhor o seu perfil.
    </OnboardingHeader>

    <VTextField
      v-if="data.exam === 'CONCURSO'"
      v-model="data.concursoName"
      variant="outlined"
      rounded="full"
      color="primary"
      hide-details
      class="mt-10"
      autofocus
      label="Nome do concurso"
      placeholder="Insira o nome do concurso"
    />

    <VTextField
      v-if="data.exam === 'OUTRO_VESTIBULAR'"
      v-model="data.vestibularName"
      variant="outlined"
      rounded="full"
      color="primary"
      hide-details
      class="mt-10"
      autofocus
      label="Vestibular"
      placeholder="Insira o vestibular"
    />

    <Chooser
      v-if="data.exam === 'ENEM'"
      v-model="data.lastEnemGrade"
      class="mt-4"
      :options="[
        {
          value: 'Menos de 500',
          name: 'Menos de 500',
        },
        {
          value: 'De 500 a 600',
          name: 'De 500 a 600',
        },
        {
          value: 'De 600 a 700',
          name: 'De 600 a 700',
        },
        {
          value: 'De 700 a 800',
          name: 'De 700 a 800',
        },
        {
          value: 'Mais de 800',
          name: 'Mais de 800',
        },
        {
          value: 'Prefiro não informar',
          name: 'Prefiro não informar',
        },
      ]"
    />
  </div>
</template>

<script lang="ts" setup>
import OnboardingHeader from '../header.vue'
import Chooser from '../chooser.vue'
import { useOnboarding } from '../../composables/useOnboarding'

const { data } = useOnboarding()

const title = computed(() => {
  switch (data.exam) {
    case 'ENEM':
      return 'Qual foi sua nota da redação no ultimo ENEM?'
    case 'CONCURSO':
      return 'Para qual concurso você está se preparando?'
    case 'OUTRO_VESTIBULAR':
      return 'Para qual vestibular você está se preparando?'
    default:
      return ''
  }
})
</script>
