const isOpened = ref(false);
const currentNotificationId = ref<string | null>(null);

export function useNotificationViewer() {
  const open = (notificationId: string) => {
    isOpened.value = true;
    currentNotificationId.value = notificationId;
  };

  return {
    currentNotificationId,
    isOpened,
    open,
    close: () => {
      isOpened.value = false;
    },
  };
}
