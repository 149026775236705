<template>
  <div>
    <OnboardingHeader title="Quais seu principal objetivo no coRedação?">
      Isso vai nos ajudar a melhorar sua experiência.
    </OnboardingHeader>

    <Chooser
      v-model="data.goal"
      class="mt-4"
      :options="[
        {
          value: 'Descobrir a nota da minha redação',
          name: 'Descobrir a nota da minha redação',
        },
        {
          value: 'Descobrir o que estou errando na minha redação',
          name: 'Descobrir o que estou errando na minha redação',
        },
        {
          value: 'Aprender a escrever com o curso, apostila e conteúdos',
          name: 'Aprender a escrever com o curso, apostila e conteúdos',
        },
        {
          value: 'Ver modelos de redação',
          name: 'Ver modelos de redação',
        },
      ]"
    />
  </div>
</template>

<script lang="ts" setup>
import OnboardingHeader from '../header.vue'
import Chooser from '../chooser.vue'
import { useOnboarding } from '../../composables/useOnboarding'

const { data } = useOnboarding()
</script>
