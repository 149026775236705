<template>
  <div>
    <div class="celebration-image d-flex justify-center">
      <img
        :class="{
          '!w-[300px]': display.height.value < 650,
        }"
        src="~/assets/images/celebration.png"
      />
    </div>
    <OnboardingHeader title="Que bom te ver por aqui">
      A partir de agora você terá correções de redações com notas precisas,
      comentários detalhados e conteúdos objetivos para aumentar sua nota.
    </OnboardingHeader>
  </div>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify";
import OnboardingHeader from "../header.vue";

const display = useDisplay();
</script>

<style lang="sass">
.celebration-image img
  width: 500px
  max-width: 100%
</style>
