<template>
  <ul class="-mx-6 -my-6">
    <li class="px-6 py-3 font-bold">Olá, {{ data?.fullname }}</li>
    <li
      v-for="item in items"
      :key="item.name"
      v-ripple="{ class: `primary` }"
      class="select-none border-t cursor-pointer hover:bg-primary/5 font-bold text-primary"
      @click="item.action ? executeAction(item.action) : null"
    >
      <span class="block px-6 py-3" v-if="!item.to">{{ item.name }}</span>
      <a class="block px-6 py-3" v-else target="_blank" :href="item.to">{{
        item.name
      }}</a>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { useNavPopover } from "../../composables/useNavPopover";
import { useCheckout } from "~/domains/checkout/composables/useCheckout";
import { useAuth } from "~/domains/auth/composables/useAuth";

const router = useRouter();
const { data, isPremium } = useAuth();
const { closeMenu } = useNavPopover();
const { openCheckout } = useCheckout();

const { $pwa } = useNuxtApp();

onMounted(() => {
  console.log('$pwa?.isPWAInstalled', $pwa?.isPWAInstalled)
  console.log('$pwa?.showInstallPrompt', $pwa?.showInstallPrompt)
  console.log('$pwa?.needRefresh', $pwa?.needRefresh)
})

const shouldShowInstall = computed(() => {
  return !$pwa?.isPWAInstalled && $pwa?.showInstallPrompt;
});

const items = [
  {
    name: "Gerenciar Conta",
    action: () => {
      router.push("/conta");
    },
  },
  ...(!isPremium.value
    ? [
        {
          name: "Seja Premium ⭐",
          action: () => {
            openCheckout();
          },
        },
      ]
    : []),
  {
    name: "Precisa de ajuda?",
    to: "https://coredacao.com/whatsapp-suporte",
  },
  ...(shouldShowInstall.value
    ? [
        {
          name: "Instalar o App 📱",
          action: async () => {
            console.log("$pwa?.showInstallPrompt", $pwa?.showInstallPrompt);
            await $pwa?.install();
          },
        },
      ]
    : []),
  {
    name: "Sair",
    action: () => {
      router.push("/sair");
    },
  },
];

const executeAction = (action: () => void) => {
  action();
  closeMenu();
};
</script>
