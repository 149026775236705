<template>
  <div class="">
    <h3 class="">Notificações</h3>

    <hr class="h-[1px] w-full bg-accent/50 my-4" />

    <div class="flex flex-col gap-2 -mx-2">
      <div
        v-for="not in data"
        :key="not.id"
        class="relative flex items-center bg-gray-50/20 hover:bg-gray-100 cursor-pointer rounded-[12px] transition-all border-[1px] border-accent/20 py-4 px-2 select-none"
        v-ripple="{ class: 'text-primary/50' }"
        :class="{
          '!bg-gray-100/90 hover:!bg-gray-100': !not.read
        }"
        @click="open(not.id)"
      >
        <div
          class="ml-2 rounded-full bg-gray-50 inline-flex items-center justify-center"
        >
          <img
            src="/images/icons/notification.svg"
            class="w-6 h-6 opacity-50"
          />
        </div>

        <div class="ml-4 pr-4">
          <div class="font-semibold">{{ not.title }}</div>
        </div>

        <div v-if="!not.read" class="absolute rounded-full w-2 h-2 animate-pulse bg-primary right-3 top-1/2 -translate-y-1/2"></div>
      </div>
      <div v-if="data?.length === 0" class="text-center">
        Você não possui nenhuma notificação.
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query';
import { getNotificationsQuery } from '../../queries/getNotificationsQuery';
import { useNotificationViewer } from '../../composables/useNotificationViewer';

const { data } = useQuery(getNotificationsQuery())
const { open } = useNotificationViewer()
</script>